import React from "react"

import { getSession } from "../../../services/auth"
import { Link } from  "../../../services/routes" // "gatsby"
import routes from "../../../services/routes"

export default () => {

	const session = getSession();

	return (
	<section className="home_banner_area">
		<div className="banner_inner">
			<div className="container">
				<div className="row">
					<div className="col-lg-5">
						<div className="banner_content">
							<h2>
								A tablet-optional <br />
								drawing space
							</h2>
							<p>
								Scratchwork supports any writing surface–even ordinary paper and dry erase boards. Everyone can use
								Scratchwork.
							</p>
							{ (session.user === null) &&
								<Link className="banner_btn" to={routes.demo}>
									Try Demo
								</Link>
							}
							{ (session.user === null) &&
								<Link className="banner_btn2" to={routes.signup}>
									Sign Up
								</Link>
							}
							{ (session.user !== null) &&
								<Link className="banner_btn2" to={routes.new_board}>
									New Board
								</Link>
							}
						</div>
					</div>
					<div className="col-lg-7">
						<div className="home_left_img">
							<video className="img-fluid" loop={true} autoPlay={true} muted={true}>
								<source src="https://s3.us-east-2.amazonaws.com/scratchworktool-static-content/demo-720p.mp4" />
							</video>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	);
};