import React from "react"

import WebsiteLayout from '../components/site/website_layout'
import LandingPage from '../components/site/landing_page'

export default () => (
  <WebsiteLayout>
    <LandingPage />
  </WebsiteLayout>
);
