import React from "react"

import lnr from "../static/vendors/linericon/style.module.css"

export default () => (
	<section className="work_area p_120">
		<div className="container">
			<div className="main_title">
				<h2>Drawing Made Easy</h2>
				<p>
					Scratchwork goes beyond the traditional online whiteboard model with usage scenarios designed specifically for
					classrooms and engineering teams and an api for developers.
				</p>
			</div>
			<div className="work_inner row">
				<div className="col-lg-4">
					<div className="work_item">
						<i className={`${lnr.lnr} ${lnr.lnrPencil}`}></i>
						<a href="#">
							<h4>Draw Anywhere</h4>
						</a>
						<p>Scratchwork extracts drawings from photos, making ordinary paper and dry erase boards workable.</p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="work_item">
						<i className={`${lnr.lnr} ${lnr.lnrSelect}`}></i>
						<a href="#">
							<h4>Develop Your Ideas</h4>
						</a>
						<p>
							Edit and reorganize drawings. Write on infinite space, blank pages, slides, and documents. When finished,
							print or export them as a PDF.
						</p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="work_item">
						<i className={`${lnr.lnr} ${lnr.lnrEarth}`}></i>
						<a href="#">
							<h4>Collaborate</h4>
						</a>
						<p>Share and edit with others in real time. Text chat, audio, and video calls are built in.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
)
