import React from "react"

import Carousel from "react-bootstrap/Carousel"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import fa from "../static/vendors/fontawesome/fontawesome.module.css"
import css from "./3_testimonials.module.css"

const FiveStarRating = () => (
	<div className="rating">
		<i className={`${fa.fa} ${fa.faStar}`}></i>
		<i className={`${fa.fa} ${fa.faStar}`}></i>
		<i className={`${fa.fa} ${fa.faStar}`}></i>
		<i className={`${fa.fa} ${fa.faStar}`}></i>
		<i className={`${fa.fa} ${fa.faStar}`}></i>
	</div>
);

// fixed height is to keep the next section from moving up and down when the carousel changes items with different heights
const Testimonial = ({comment, name}) => (
	<div className="testi_item" style={{height:"275px"}}>
		<div className="media">
			<div className="media-body">
				<p>{comment}</p>
				<h4>{name}</h4>
				<FiveStarRating />
			</div>
		</div>
	</div>
)

const TheCarousel = () => (
	<Carousel
		controls={false}
		indicators={false}
		interval={3000}
	>
		<Carousel.Item>
			<Row>
				<Col>
					<Testimonial
						comment="I really like it, very convenient, easy to use and functional application."
						name="Max"
					/>
				</Col>
				<Col>
					<Testimonial
						comment="I haven't found any other apps that can substitute as a collaborative tool."
						name="Jonathan"
					/>
				</Col>
			</Row>
		</Carousel.Item>
		<Carousel.Item>
			<Row>
				<Col>
					<Testimonial
						comment="I think your product is WAY better than the other stuff that's out there. Great job!"
						name="John"
					/>
				</Col>
				<Col>
					<Testimonial
						comment="It's so hard to write your thinkings in electronic format when more than half of it is formulas and graphics. So I really love your tool!"
						name="Alexandra"
					/>
				</Col>
			</Row>
		</Carousel.Item>
		<Carousel.Item>
			<Row>
				<Col>
					<Testimonial
						comment="I LOVE it! I use it for my online office hours for students. It makes being able to write math so much easier."
						name="Jennifer"
					/>
				</Col>
				<Col>
					<Testimonial
						comment="For a long time I was looking for the perfect board for conducting classes, and I finally found it!"
						name="Olga"
					/>
				</Col>
			</Row>
		</Carousel.Item>
	</Carousel>
)

export default () => (
	<section className={`${css.fadeTop} p_120`}>
		<div className="container">
			<div className="main_title">
				<h2>Customer Reviews</h2>
				<p>We've heard great things from our users. And we'd love to hear from you too!</p>
			</div>
			<TheCarousel />
		</div>
	</section>
)
