import React from "react"

import princeton from "../static/img/supporters/princeton-university.png"
import elab from "../static/img/supporters/elab.jpg"
import tigerchallenge from "../static/img/supporters/tigerchallenge.png"
import aws_member from "../static/img/supporters/AWS-Member.jpg"
import kingscollege from "../static/img/supporters/kingscollege.jpg"
import techcelerator from "../static/img/supporters/techcelerator.png"

export default () => (
	<section className="made_life_area made_white p_120">
		<div className="container">
			<div className="made_life_inner">
				<div className="row made_life_text">
					<div className="col-lg-6">
						<div className="left_side_text">
							<h3>
								A special thanks to
								<br />
								our supporters!
							</h3>
							<p>
								Scratchwork was made possible with support and guidance from Princeton, Penn State, and Cambridge
								Universities.
							</p>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="chart_img">
							<div style={{maxWidth:"30%", display:"inline-block", verticalAlign:"middle", marginRight:"20px"}}>
								<img style={{maxWidth:"100%"}} src={princeton} />
								<img style={{maxWidth:"100%", marginTop:"-10%"}} src={elab} />
							</div>
							<img style={{maxWidth:"30%"}} src={tigerchallenge} />
							<img style={{maxWidth:"30%"}} src={aws_member} />
							<div style={{maxWidth:"100%", display:"inline-block", verticalAlign:"middle"}}>
								<img style={{maxWidth:"49%"}} src={kingscollege} />
								<img
									style={{maxWidth:"49%", verticalAlign:"middle"}}
									src={techcelerator}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)
